import axios from 'axios';
import {Auth} from 'aws-amplify';
import Cookie from 'js-cookie';

const url = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const sendToken = async (token, refresh = '') => {
  await api.post(
    '/authorization_tokens',
    {
      refreshToken: refresh,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );
};

export const RdEventsSignup = async (name, email) => {
  await api.post(`/rd/events`, {
    eventType: 'CONVERSION',
    eventFamily: 'CDP',
    payload: {
      name,
      email: email.toLowerCase(),
      conversion_identifier: 'cadastro_hub',
    },
  });
};

export const RdLeadsSignup = async (name, email) => {
  await api.post(`/rd/contact`, {
    name,
    email: email.toLowerCase(),
    tags: ['cadastro_hub'],
  });
};

export const signIn = async (email, password) => {
  const response = await Auth.signIn(email.toLowerCase(), password);
  if (window.location.origin.indexOf('localhost') !== -1) {
    Cookie.set('authB3', {auth: true});
  } else {
    Cookie.set('authB3', {auth: true}, {domain: process.env.REACT_APP_DOMAIN});
  }
  return response;
};
