export const settings = {
  codes: {
    hotjar: {
      hjid: 1857620,
      hjsv: 6,
    },
    gtm: {
      uaId: 'UA-161429558-2',
      gtmId: 'GTM-MQ4DM2L',
      awId: 'AW-615809848',
    },
    linkedin: {
      partnerId: '2575305',
      conversionId: '',
    },
    pixel: {
      id: '224101575526074',
    },
  },
};
