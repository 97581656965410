import React, {useEffect, useState} from 'react';
import {useAlert} from 'react-alert';
import {Auth, I18n} from 'aws-amplify';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from 'formik';
import InputMasked from 'react-input-mask';
import Base, {ColumnLeft, ColumnRight} from '../../components/Base';
import {
  Button,
  ButtonNoFormat,
  ButtonSecondary,
  CapsLock,
  Form,
  FormControl,
  H1,
  Hint,
  InputError,
  Link
} from '../../assets/styles/GlobalStyles';
import {verifyError, passwordValidation} from '../../utils/verifyErros';
import {Input, InputPassword} from '../../components/Base/Elements';
import {signIn, sendToken} from '../../services/api';
import { IcoView } from '../../components/Base/styled';

const PasswordRecovery = ({history}) => {
  const alert = useAlert();
  const email = history.location.state ? history.location.state.email || '' : '';
  const [passwordValidate, setPasswordValidate] = useState({
    min: false,
    number: false,
    letterlowercase: false,
    letteruppercase: false,
    letterespecial: false
  });
  const [viewPass, setViewPass] = useState(false);
  const [capsLock, setCapsLock] = useState(false);

  const verifyCapsLock = (event) => {
    setCapsLock(event.getModifierState && event.getModifierState('CapsLock'));
  };

  useEffect(() => {
    document.addEventListener('keydown', verifyCapsLock);
    return () => {
      document.removeEventListener('keydown', verifyCapsLock);
    };
  });

  const resendConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(email);
      alert.show(I18n.get('confirmSignUp.codeResentSuccessfully'), {
        closeCopy: 'Ok'
      });
    } catch (e) {
      alert.show(I18n.get('confirmSignUp.errorResendingCode'), {
        closeCopy: 'Ok'
      });
    }
  };

  return (
    <Base>
      <ColumnLeft>
        <p>{I18n.get('register.columnLeft.title')}</p>
        <ButtonSecondary
          onClick={() => {
            history.push({
              pathname: '/login',
              search: history.location.search,
              state: {
                email
              }
            });
          }}>
          {I18n.get('register.columnLeft.login')}
        </ButtonSecondary>
      </ColumnLeft>
      <ColumnRight>
        <Formik
          enableReinitialize
          initialValues={{
            email,
            code: '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(I18n.get('validate.invalid_email'))
              .required(I18n.get('validate.required')),
            code: Yup.string().when('checked', {
              is: (val) => val && val === 'checked',
              then: Yup.string()
                .required(I18n.get('validate.required'))
                .matches(/[0-9]/, I18n.get('validate.code.only_number'))
            }),
            password: Yup.string().when('checked', {
              is: (val) => val && val === 'checked',
              then: Yup.string()
                .required(I18n.get('validate.required'))
                .min(8, I18n.get('validate.password.min'))
                .max(24, I18n.get('validate.password.max'))
                .matches(/[a-z]/, I18n.get('validate.password.one_lowercase_character'))
                .matches(/[A-Z]/, I18n.get('validate.password.one_uppercase_character'))
                .matches(/[0-9]/, I18n.get('validate.password.one_number'))
                .matches(
                  /[!@#$%*()_/\\\-+^&{}:;?.]/,
                  I18n.get('validate.password.one_special_character')
                )
            })
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              try {
                await Auth.forgotPasswordSubmit(
                  values.email.toLowerCase(),
                  values.code,
                  values.password
                );
                alert.show(I18n.get('ForgotPasswordException.passwordChangedSuccessfully'), {
                  closeCopy: 'Ok'
                });
                const response = await signIn(values.email.toLowerCase(), values.password, true);
                await sendToken(
                  response.signInUserSession.idToken.jwtToken,
                  response.signInUserSession.refreshToken.token
                );
                history.push({
                  pathname: '/',
                  search: history.location.search
                });
              } catch (e) {
                alert.show(verifyError(e.code, e.message), {
                  closeCopy: 'Ok'
                });
              }
            }
            actions.setSubmitting(false);
          }}>
          {({isSubmitting}) => (
            <Form>
              <H1>{I18n.get('passwordRecovery.title')}</H1>

              <FormControl>
                <Field name="email" placeholder={I18n.get('input.email')} component={Input} />
                <ErrorMessage name="email" component={InputError} />
              </FormControl>

              <>
                <FormControl>
                  <Field name="code">
                    {({field}) => (
                      <InputMasked
                        {...field}
                        placeholder={I18n.get('input.code')}
                        maskChar=""
                        mask="999999">
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMasked>
                    )}
                  </Field>
                  <ErrorMessage name="code" component={InputError} />
                </FormControl>

                <FormControl>
                  <Field
                    type={viewPass ? 'text' : 'password'}
                    name="password"
                    onInput={(value) => {
                      passwordValidation(value.target.value, setPasswordValidate);
                    }}
                    placeholder={I18n.get('input.new_password')}
                    component={Input}
                  />
                  <CapsLock className={capsLock && 'active'}>Caps Lock Ativado</CapsLock>
                  <IcoView
                    role="button"
                    tabIndex="0"
                    aria-label="Exibir conteúdo"
                    className={viewPass && 'slash'}
                    // onMouseDown={() => setViewPass(true)}
                    // onMouseUp={() => setViewPass(false)}
                    // onMouseOut={() => setViewPass(false)}
                    onClick={() => setViewPass((prev) => !prev)}
                    // onBlur={() => setViewPass(false)}
                  />
                  <ErrorMessage name="password" component={InputError} />
                  <Hint>
                    <div>{I18n.get('input.password.hint_title')}</div>
                    <ul>
                      <li className={`${passwordValidate.min ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint1')}
                      </li>
                      <li className={`${passwordValidate.number ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint2')}
                      </li>
                      <li className={`${passwordValidate.letterlowercase ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint3')}
                      </li>
                      <li className={`${passwordValidate.letteruppercase ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint4')}
                      </li>
                      <li className={`${passwordValidate.letterespecial ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint5')}
                      </li>
                    </ul>
                  </Hint>
                </FormControl>
              </>

              <FormControl>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? I18n.get('button.sending') : I18n.get('button.send')}
                </Button>
              </FormControl>

              {!!email && (
                <FormControl>
                  <Link as={ButtonNoFormat} onClick={resendConfirmationCode}>
                    {I18n.get('confirmSignUp.resendConfirmationCode')}
                  </Link>
                </FormControl>
              )}
            </Form>
          )}
        </Formik>
      </ColumnRight>
    </Base>
  );
};

export default PasswordRecovery;
