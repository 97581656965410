import React from 'react';
import PropTypes from 'prop-types';
import {ColumnLeft as ColumnLeftStyled, Logo} from './styled';

const ColumnLeft = ({children}) => {
  return (
    <ColumnLeftStyled>
      <a href={process.env.REACT_APP_ACADEMY_URL} rel="noopener noreferrer" target="_self">
        <Logo src="/assets/images/logo.png" alt="Logo do site" title="B3 Educação" />
      </a>
      {children}
    </ColumnLeftStyled>
  );
};

ColumnLeft.propTypes = {
  children: PropTypes.any.isRequired,
};
export {ColumnLeft};
