import React, {useEffect, useState} from 'react';
import {Auth, I18n} from 'aws-amplify';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from 'formik';
import {useAlert} from 'react-alert';
import {passwordValidation, verifyError} from '../../utils/verifyErros';
import {Input, InputPassword} from '../../components/Base/Elements';
import Base, {ColumnLeft, ColumnRight} from '../../components/Base';
import {RdEventsSignup, RdLeadsSignup, signIn, sendToken} from '../../services/api';
import {
  Button,
  ButtonSecondary,
  Form,
  FormControl,
  H1,
  InputError,
  Label,
  Hint,
  Link,
  CapsLock
} from '../../assets/styles/GlobalStyles';
import { IcoView } from '../../components/Base/styled';

const SignIn = ({history}) => {
  const alert = useAlert();
  const [passView, setPassView] = useState(null);
  const [viewPass, setViewPass] = useState(false);
  const [viewPass2, setViewPass2] = useState(false);
  const [capsLock, setCapsLock] = useState(false);
  const [passwordValidate, setPasswordValidate] = useState({
    min: false,
    number: false,
    letterlowercase: false,
    letteruppercase: false,
    letterespecial: false
  });
  const hasEmail = history.location.state ? history.location.state.email || '' : '';

  const verifyCapsLock = (event) => {
    setCapsLock(event.getModifierState && event.getModifierState('CapsLock'));
  };

  useEffect(() => {
    document.addEventListener('keydown', verifyCapsLock);
    return () => {
      document.removeEventListener('keydown', verifyCapsLock);
    };
  });

  return (
    <Base>
      <ColumnLeft>
        <p>{I18n.get('login.columnLeft.title')}</p>
        <ButtonSecondary
          onClick={() => {
            history.push({
              pathname: '/cadastro',
              search: history.location.search
            });
          }}>
          {I18n.get('login.columnLeft.register')}
        </ButtonSecondary>
      </ColumnLeft>
      <ColumnRight>
        <Formik
          enableReinitialize
          initialValues={{
            email: hasEmail,
            password: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(I18n.get('validate.invalid_email'))
              .required(I18n.get('validate.required')),
            password: Yup.string()
              .min(8, I18n.get('validate.min_password'))
              .required(I18n.get('validate.required'))
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              if (!passView) {
                try {
                  const response = await signIn(values.email.toLowerCase(), values.password);

                  if (
                    history.location &&
                    history.location.state &&
                    history.location.state.origin === '/cadastro-verificacao'
                  ) {
                    await RdEventsSignup(response.attributes.name, response.attributes.email);
                    await RdLeadsSignup(response.attributes.name, response.attributes.email);
                  }
                  if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    await Auth.completeNewPassword(response, values.password);
                    await sendToken(
                      response.signInUserSession.idToken.jwtToken,
                      values && values.term ? response.signInUserSession.refreshToken.token : ''
                    );
                    history.push({
                      pathname: '/',
                      search: history.location.search
                    });
                  } else if (response.challengeName !== 'PASSWORD_VERIFIER') {
                    await sendToken(
                      response.signInUserSession.idToken.jwtToken,
                      values && values.term ? response.signInUserSession.refreshToken.token : ''
                    );
                    history.push({
                      pathname: '/',
                      search: history.location.search
                    });
                  }
                } catch (e) {
                  if (e.code === 'UserNotConfirmedException') {
                    try {
                      await Auth.resendSignUp(values.email.toLowerCase());
                      alert.show(I18n.get('send.code.email'), {
                        closeCopy: 'Ok'
                      });
                    } catch (e) {
                      alert.show(I18n.get('send.code.email'), {
                        closeCopy: 'Ok'
                      });
                    }
                    history.push({
                      pathname: '/cadastro-verificacao',
                      search: history.location.search,
                      state: {
                        email: values.email.toLowerCase()
                      }
                    });
                  } else if (e.code === 'UserLambdaValidationException') {
                    history.push({
                      pathname: '/nova-senha',
                      search: history.location.search,
                      state: {
                        email: values.email.toLowerCase()
                      }
                    });
                  } else {
                    alert.show(verifyError(e.code, e.message), {
                      closeCopy: 'Ok'
                    });
                  }
                }
              } else {
                const loggedUser = await Auth.completeNewPassword(
                  passView, // the Cognito User Object
                  values.password // the new password
                );
                alert.show(
                  'Senha alterada com Sucesso! Faça login Novamente para acessar a plataforma.',
                  {
                    closeCopy: 'Ok'
                  }
                );
                setPassView(loggedUser ? null : loggedUser);
              }
            }
            actions.setSubmitting(false);
          }}>
          {({isSubmitting}) => (
            <Form>
              {!passView ? (
                <>
                  <H1>{I18n.get('login.title')}</H1>

                  <FormControl>
                    <Field name="email" placeholder={I18n.get('input.email')} component={Input} />
                    <ErrorMessage name="email" component={InputError} />
                  </FormControl>

                  <FormControl>
                    <Field
                      type={viewPass ? 'text' : 'password'}
                      name="password"
                      placeholder={I18n.get('input.password')}
                      component={Input}
                    />
                    <CapsLock className={capsLock && 'active'}>Caps Lock Ativado</CapsLock>
                    <IcoView
                      role="button"
                      tabIndex="0"
                      aria-label="Exibir conteúdo"
                      className={viewPass && 'slash'}
                      // onMouseDown={() => setViewPass(true)}
                      // onMouseUp={() => setViewPass(false)}
                      // onMouseOut={() => setViewPass(false)}
                      onClick={() => setViewPass((prev) => !prev)}
                      // onBlur={() => setViewPass(false)}
                    />
                    <ErrorMessage name="password" component={InputError} />
                  </FormControl>

                  <FormControl>
                    <Label htmlFor="term">
                      <Field type="checkbox" id="term" name="term" />
                      Manter conectado
                    </Label>
                  </FormControl>
                </>
              ) : (
                <div>
                  <H1>{I18n.get('login.new_password')}</H1>
                  <FormControl>
                    <Field
                      type={viewPass2 ? 'text' : 'password'}
                      name="password"
                      placeholder={I18n.get('input.password')}
                      component={Input}
                      onInput={(value) => {
                        passwordValidation(value.target.value, setPasswordValidate);
                      }}
                    />
                    <CapsLock className={capsLock && 'active'}>Caps Lock Ativado</CapsLock>
                    <IcoView
                      role="button"
                      tabIndex="0"
                      aria-label="Exibir conteúdo"
                      className={viewPass2 && 'slash'}
                      // onMouseDown={() => setViewPass(true)}
                      // onMouseUp={() => setViewPass(false)}
                      // onMouseOut={() => setViewPass(false)}
                      onClick={() => setViewPass2((prev) => !prev)}
                      // onBlur={() => setViewPass(false)}
                    />
                    <ErrorMessage name="password" component={InputError} />
                    <Hint>
                      <div>{I18n.get('input.password.hint_title')}</div>
                      <ul>
                        <li className={`${passwordValidate.min ? 'complete' : ''}`}>
                          {I18n.get('input.password.hint1')}
                        </li>
                        <li className={`${passwordValidate.number ? 'complete' : ''}`}>
                          {I18n.get('input.password.hint2')}
                        </li>
                        <li className={`${passwordValidate.letterlowercase ? 'complete' : ''}`}>
                          {I18n.get('input.password.hint3')}
                        </li>
                        <li className={`${passwordValidate.letteruppercase ? 'complete' : ''}`}>
                          {I18n.get('input.password.hint4')}
                        </li>
                        <li className={`${passwordValidate.letterespecial ? 'complete' : ''}`}>
                          {I18n.get('input.password.hint5')}
                        </li>
                      </ul>
                    </Hint>
                  </FormControl>
                </div>
              )}

              <FormControl>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting
                    ? I18n.get(passView ? 'button.altering' : 'button.entering')
                    : I18n.get(passView ? 'button.alter' : 'button.enter')}
                </Button>
              </FormControl>
              {!passView && (
                <FormControl>
                  <Link
                    to={{
                      pathname: '/esqueci-minha-senha',
                      search: history.location.search
                    }}>
                    {I18n.get('login.resetPassword')}
                  </Link>
                </FormControl>
              )}
            </Form>
          )}
        </Formik>
      </ColumnRight>
    </Base>
  );
};

export default SignIn;
