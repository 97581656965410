import {useCallback, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import queryString from 'query-string';
import Cookie from 'js-cookie';
import {clearCookie, createCookie} from '../utils/cookies';
import {hasProperty} from '../utils/hasProperty';
import {getRedirect} from '../utils/getRedirect';
import {redirectTo} from '../utils/redirectTo';
import {api} from '../services/api';

const Home = ({history}) => {
  const goToUrl = useCallback(() => {
    const {location} = history;
    const buildUrlToAdmin = (redirect) => {
      history.push(`${redirect}&adm=true`);
    };

    const buildUrlToWebsite = (redirect) => {
      history.push(redirect);
    };

    if (location.search) {
      const search = queryString.parse(location.search);
      if (hasProperty(search, 'adm')) {
        buildUrlToAdmin(getRedirect(search));
      } else {
        buildUrlToWebsite(getRedirect(search));
      }
    } else {
      buildUrlToWebsite(getRedirect());
    }
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const response = await Auth.currentSession();
        Object.keys(localStorage).forEach((key) => {
          if (window.location.origin.indexOf('localhost') !== -1) {
            Cookie.set(key, localStorage.getItem(key));
          }
          if (key.indexOf('CognitoIdentityServiceProvider') !== -1) {
            Cookie.set(key, localStorage.getItem(key), {
              domain: process.env.REACT_APP_DOMAIN
            });
          }
        });
        createCookie(response);
        const res = await api.get(`/me`, {
          headers: {
            Authorization: response.idToken.jwtToken
          }
        });

        const notification = history.location.state ? history.location.state.notification : false;
        if (notification) {
          await api.put(`/users/${res.data.user.id}/notification`, {
            notificationEmail: true
          });
        }

        const {location} = history;
        const search = queryString.parse(location.search);
        if (hasProperty(search, 'signUp') || location.pathname.indexOf('registre-se') !== -1) {
          goToUrl();
        } else {
          redirectTo(`${history.location.search}`);
        }
      } catch (e) {
        clearCookie();
        goToUrl();
      }
    })();
  }, [history, goToUrl]);

  return null;
};
export default Home;
