import {useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {clearCookie} from '../../utils/cookies';
import {api} from '../../services/api';

const Logout = ({history}) => {
  useEffect(() => {
    (async () => {
      const redirect = () => {
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_ACADEMY_URL}`;
        }, 300);
      };
      try {
        // const response = await Auth.currentSession();
        await Auth.signOut({global: true});
        await api.get('/invalidate_session');
        localStorage.clear();
        clearCookie();
        redirect();
      } catch (e) {
        localStorage.clear();
        clearCookie();
        redirect();
      }
    })();
  }, [history]);

  return null;
};

export default Logout;
