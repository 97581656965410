import styled, {createGlobalStyle} from 'styled-components';
import {Form as FormFormik} from 'formik';
import {Link as LinkRouter} from 'react-router-dom';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  *, button, input {
    border: 0;
    outline: 0;
    font-family: 'Mulish', sans-serif;
  }
  
  body{
    font-size: 16px;
    color: #313131;
  }
  
  .complete {
    text-decoration: line-through;
  }
  
  [type="checkbox"]:focus, [type="radio"]:focus {
    box-shadow: inset 0 0 1px 1px #00b0e6;
  }
`;

export const ButtonDefault = styled.button`
  line-height: 48px;
  border-radius: 30px;
  padding: 0 45px;
  margin: 15px 0;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 15px;
  transition: all 300ms ease;
  font-weight: 600;
  &[disabled] {
    opacity: 0.4;
    cursor: no-drop;
  }
`;

export const Button = styled(ButtonDefault)`
  background: #ffd862;
  border: 1px solid #ffd862;
  color: #002c63;
  &:hover {
    background-color: rgba(255, 216, 98, 0.5);
  }
`;

export const ButtonSecondary = styled(ButtonDefault)`
  background: none;
  border: 1px solid #fff;
  color: #fff;
  &:hover {
    background-color: rgba(0, 176, 230, 0.25);
  }
`;

export const ButtonNoFormat = styled.div`
  cursor: pointer;
`;

export const H1 = styled.h1`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin: 0 0 30px;
`;

export const Form = styled(FormFormik)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormControl = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

export const Label = styled.label`
  color: #313131;
  font-size: 14px;
  text-align: left;
  display: block;
  > input {
    margin-right: 5px;
  }
  > a {
    color: #00b0e6;
  }
`;

export const Hint = styled.div`
  font-size: 12px;
  color: #676767;
  text-align: left;
  > div {
    margin-top: 3px;
    font-weight: bold;
  }
  ul {
    padding-left: 20px;
    li {
      font-size: 11px;
    }
  }
`;

export const InputError = styled.div`
  font-size: 12px;
  color: #990000;
  text-align: left;
  margin-top: 3px;
`;

export const Input = styled.input`
  height: 38px;
  background: #e5e7eb;
  padding: 0 10px;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent #00b0e6;
  transition: border 300ms ease;
  &:focus {
    border-color: #3898ec;
  }
`;

export const Link = styled(LinkRouter)`
  display: inline-flex;
  color: #1a6ee0;
  font-size: 16px;
  &:hover {
    text-decoration: none;
  }
`;

export const Relative = styled.div`
  position: relative;
`;

export const CapsLock = styled.div`
  position: absolute;
  bottom: -15px;
  font-size: 12px;
  background: #eee;
  color: #555;
  padding: 0 5px;
  line-height: 22px;
  border: 1px solid #3898ec;
  z-index: 9;
  right: 10px;
  display: none;
  &.active {
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    top: -7px;
    right: 0;
    border-bottom: 7px solid #3898ec;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
  &:after {
    content: '';
    position: absolute;
    top: -6px;
    right: 1px;
    border-bottom: 7px solid #eee;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;
