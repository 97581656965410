import React from 'react';
import {Auth, I18n} from 'aws-amplify';
import InputMasked from 'react-input-mask';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from 'formik';
import {useAlert} from 'react-alert';
import {useHistory} from 'react-router-dom';
import Base, {ColumnLeft, ColumnRight} from '../../components/Base';
import {RdEventsSignup, RdLeadsSignup, signIn, sendToken} from '../../services/api';
import {
  Button,
  ButtonNoFormat,
  ButtonSecondary,
  Form,
  FormControl,
  H1,
  InputError,
  Link,
} from '../../assets/styles/GlobalStyles';
import {verifyError} from '../../utils/verifyErros';
import {Input} from '../../components/Base/Elements';

const ConfirmSignUp = () => {
  const alert = useAlert();
  const history = useHistory();
  const hasEmail = history.location.state ? history.location.state.email || '' : '';
  const hasPassword = history.location.state ? history.location.state.password || '' : '';
  const notification = history.location.state ? history.location.state.notification : false;

  const resendConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(hasEmail);
      alert.show(I18n.get('confirmSignUp.codeResentSuccessfully'), {
        closeCopy: 'Ok',
      });
    } catch (e) {
      alert.show(I18n.get('confirmSignUp.errorResendingCode'), {
        closeCopy: 'Ok',
      });
    }
  };

  return (
    <Base>
      <ColumnLeft>
        <p>{I18n.get('register.columnLeft.title')}</p>
        <ButtonSecondary
          onClick={() => {
            history.push({
              pathname: '/login',
              search: history.location.search,
            });
          }}>
          {I18n.get('register.columnLeft.login')}
        </ButtonSecondary>
      </ColumnLeft>
      <ColumnRight>
        <Formik
          enableReinitialize
          initialValues={{
            email: hasEmail,
            code: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(I18n.get('validate.invalid_email'))
              .required(I18n.get('validate.required')),
            code: Yup.string()
              .required(I18n.get('validate.required'))
              .matches(/[0-9]/, I18n.get('validate.code.only_number')),
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              try {
                await Auth.confirmSignUp(values.email.toLowerCase(), values.code);

                if (hasPassword) {
                  const userLogged = await signIn(values.email.toLowerCase(), hasPassword, true);
                  await sendToken(
                    userLogged.signInUserSession.idToken.jwtToken,
                    userLogged.signInUserSession.refreshToken.token,
                  );
                  await RdEventsSignup(
                    userLogged.attributes.name,
                    userLogged.attributes.email,
                    userLogged.signInUserSession.idToken.jwtToken,
                  );
                  await RdLeadsSignup(
                    userLogged.attributes.name,
                    userLogged.attributes.email,
                    userLogged.signInUserSession.idToken.jwtToken,
                  );
                  history.push({
                    pathname: '/',
                    search: history.location.search,
                    state: {
                      email: values.email.toLowerCase(),
                      notification,
                    },
                  });
                } else {
                  alert.show(I18n.get('confirmSignUp.verifiedUser'), {
                    closeCopy: 'Ok',
                  });
                  history.push({
                    pathname: '/login',
                    search: history.location.search,
                    state: {
                      email: values.email.toLowerCase(),
                      origin: history.location.pathname,
                    },
                  });
                }
              } catch (e) {
                actions.setFieldValue('code', '');
                alert.show(verifyError(e.code, e.message), {
                  closeCopy: 'Ok',
                });
              }
            }
            actions.setSubmitting(false);
          }}>
          {({isSubmitting}) => (
            <Form>
              <H1>{I18n.get('confirmSignUp.title')}</H1>

              <FormControl>
                <Field
                  name="email"
                  disabled={!!hasEmail}
                  placeholder={I18n.get('input.email')}
                  component={Input}
                />
                <ErrorMessage name="email" component={InputError} />
              </FormControl>

              <FormControl>
                <Field name="code">
                  {({field}) => (
                    <InputMasked
                      {...field}
                      placeholder={I18n.get('input.code')}
                      maskChar=""
                      mask="999999">
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMasked>
                  )}
                </Field>
                <ErrorMessage name="code" component={InputError} />
              </FormControl>

              <FormControl>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? I18n.get('button.sending') : I18n.get('button.send')}
                </Button>
              </FormControl>

              {!!hasEmail && (
                <FormControl>
                  <Link as={ButtonNoFormat} onClick={resendConfirmationCode}>
                    {I18n.get('confirmSignUp.resendConfirmationCode')}
                  </Link>
                </FormControl>
              )}
            </Form>
          )}
        </Formik>
      </ColumnRight>
    </Base>
  );
};

export default ConfirmSignUp;
