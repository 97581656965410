import React from 'react';
import PropTypes from 'prop-types';
import {ColumnRight as ColumnRightStyled} from './styled';

const ColumnRight = ({children}) => {
  return <ColumnRightStyled>{children}</ColumnRightStyled>;
};

ColumnRight.propTypes = {
  children: PropTypes.any.isRequired,
};
export {ColumnRight};
