import React from 'react';
import {useAlert} from 'react-alert';
import {Auth, I18n} from 'aws-amplify';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from 'formik';
import Base, {ColumnLeft, ColumnRight} from '../../components/Base';
import {
  Button,
  ButtonSecondary,
  Form,
  FormControl,
  H1,
  InputError,
} from '../../assets/styles/GlobalStyles';
import {Input} from '../../components/Base/Elements';
import {signIn, sendToken} from '../../services/api';

const ForgotPassword = ({history}) => {
  const alert = useAlert();

  const changePasswordSubmit = async (values) => {
    await Auth.forgotPassword(values.email.toLowerCase());
    alert.show(I18n.get('ForgotPasswordException.codeSendingSuccess'), {
      closeCopy: 'Ok',
    });
    history.push({
      pathname: '/recuperar-senha',
      search: history.location.search,
      state: {
        email: values.email.toLowerCase(),
      },
    });
  };

  return (
    <Base>
      <ColumnLeft>
        <p>{I18n.get('register.columnLeft.title')}</p>
        <ButtonSecondary
          onClick={() => {
            history.push({
              pathname: '/login',
              search: history.location.search,
            });
          }}>
          {I18n.get('register.columnLeft.login')}
        </ButtonSecondary>
      </ColumnLeft>
      <ColumnRight>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            code: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(I18n.get('validate.invalid_email'))
              .required(I18n.get('validate.required')),
            code: Yup.string().when('checked', {
              is: (val) => val && val === 'checked',
              then: Yup.string()
                .required(I18n.get('validate.required'))
                .matches(/[0-9]/, I18n.get('validate.code.only_number')),
            }),
            password: Yup.string().when('checked', {
              is: (val) => val && val === 'checked',
              then: Yup.string()
                .required(I18n.get('validate.required'))
                .min(8, I18n.get('validate.password.min'))
                .max(24, I18n.get('validate.password.max'))
                .matches(/[a-z]/, I18n.get('validate.password.one_lowercase_character'))
                .matches(/[A-Z]/, I18n.get('validate.password.one_uppercase_character'))
                .matches(/[0-9]/, I18n.get('validate.password.one_number'))
                .matches(
                  /[!@#$%*()_/\\\-+^&{}:;?.]/,
                  I18n.get('validate.password.one_special_character'),
                ),
            }),
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              try {
                const response = await signIn(
                  values.email.toLowerCase(),
                  Math.random()
                    .toString(30)
                    .replace(/[^A-z0-9]+/g, '')
                    .substr(0, 8),
                  true,
                );
                await sendToken(
                  response.signInUserSession.idToken.jwtToken,
                  response.signInUserSession.refreshToken.token,
                );
              } catch (e) {
                if (e.code === 'UserLambdaValidationException') {
                  history.push({
                    pathname: '/nova-senha',
                    search: history.location.search,
                    state: {
                      email: values.email.toLowerCase(),
                    },
                  });
                } else if (
                  e.code === 'NotAuthorizedException' &&
                  e.message === 'Incorrect username or password.'
                ) {
                  await changePasswordSubmit(values);
                } else {
                  alert.show(I18n.get('ForgotPasswordException.codeSendingSuccess'), {
                    closeCopy: 'Ok',
                  });
                  history.push({
                    pathname: '/recuperar-senha',
                    search: history.location.search,
                    state: {
                      email: values.email.toLowerCase(),
                    },
                  });
                }
              }
            }
            actions.setSubmitting(false);
          }}>
          {({isSubmitting}) => (
            <Form>
              <H1>{I18n.get('forgotPassword.title')}</H1>
              <FormControl>
                <Field name="email" placeholder={I18n.get('input.email')} component={Input} />
                <ErrorMessage name="email" component={InputError} />
              </FormControl>
              <FormControl>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? I18n.get('button.sending') : I18n.get('button.send')}
                </Button>
              </FormControl>
            </Form>
          )}
        </Formik>
      </ColumnRight>
    </Base>
  );
};

export default ForgotPassword;
