import React from 'react';
import {positions, Provider} from 'react-alert';
import AlertMUITemplate from 'react-alert-template-mui';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Amplify, {I18n} from 'aws-amplify';
import queryString from 'query-string';
import {dictionary} from './dictionary/dictionary';
import awsExposts from './aws-exports';

import GlobalStyles from './assets/styles/GlobalStyles';

import Home from './pages';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Logout from './pages/Logout';
import ConfirmSignUp from './pages/ConfirmSignUp';
import ForgotPassword from './pages/ForgotPassword';
import NewPassword from './pages/NewPassword';
import TokenRefresh from './pages/TokenRefresh';
import PasswordRecovery from './pages/PasswordRecovery';
import {hasProperty} from './utils/hasProperty';

Amplify.configure(awsExposts);
I18n.setLanguage('pt_BR');
I18n.putVocabularies(dictionary);
const App = () => {
  const {location} = window;
  const search = queryString.parse(location.search);
  if (hasProperty(search, 'redirect')) {
    const urlString = atob(search.redirect);

    if (urlString.includes('@')) {
      window.location.href = `/`;
    }

    if (urlString.indexOf(process.env.REACT_APP_DOMAIN) === -1) {
      window.location.href = `/`;
    }
  }

  return (
    <>
      <GlobalStyles />
      <Provider template={AlertMUITemplate} position={positions.MIDDLE}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" component={SignIn} />
            <Route path="/cadastro" component={SignUp} />
            <Route path="/cadastro-verificacao" component={ConfirmSignUp} />
            <Route path="/esqueci-minha-senha" component={ForgotPassword} />
            <Route path="/recuperar-senha" component={PasswordRecovery} />
            <Route path="/nova-senha" component={NewPassword} />
            <Route path="/token" component={TokenRefresh} />
            <Route path="/sair" component={Logout} />
            <Route path="*" component={Home} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default App;
