import React, {useEffect} from 'react';
import {Normalize} from 'styled-normalize';
import {useHistory} from 'react-router-dom';
import {hotjar} from 'react-hotjar';
import ReactGA from 'react-ga';
import LinkedInTag from 'react-linkedin-insight';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from 'react-helmet';
import {Main, Wrapper} from './styled';
import {settings} from '../../config/settings';

export * from './ColumnLeft';
export * from './ColumnRight';

const Base = ({children}) => {
  const history = useHistory();
  hotjar.initialize(settings.codes.hotjar.hjid, settings.codes.hotjar.hjsv);
  ReactGA.initialize(settings.codes.gtm.uaId, {
    debug: false,
  });
  LinkedInTag.init(settings.codes.linkedin.partnerId);
  ReactPixel.init(
    settings.codes.pixel.id,
    {},
    {
      autoConfig: true,
      debug: false,
    },
  );
  useEffect(() => {
    ReactGA.pageview(history.location.pathname + history.location.search);
    ReactGA.event({
      category: 'Initialize',
      action: 'Navigation',
      label: 'conversion',
    });

    LinkedInTag.track(settings.codes.linkedin.conversionId);

    // For tracking page view
    ReactPixel.pageView(history.location.pathname + history.location.search);
  }, [history]);

  const Gtag = () => {
    const {pathname} = history.location;
    if (pathname === '/cadastro-verificacao' || pathname === '/cadastro') {
      return (
        <Helmet>
          {/* other stuff... */}

          {/* Global site tag (gtag.js) - Google Analytics  */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-615809848" />
        </Helmet>
      );
    }
    return null;
  };

  return (
    <>
      <Gtag />
      <Normalize />
      <Main>
        <Wrapper>{children}</Wrapper>
      </Main>
    </>
  );
};

export default Base;
