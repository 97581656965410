import styled from 'styled-components';
import BG from '../../assets/images/bg.png';
import colLeft from '../../assets/images/columnLeft.jpg';
import icoEye from '../../assets/images/ico-eye.png';

export const Main = styled.div`
  background: url("${BG}") no-repeat top center;
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  background: #fff;
  box-shadow: 0 3px 20px -1px #000;
  width: 90vw;
  @media (min-width: 768px) {
    width: 70vw;
    min-width: 800px;
    height: 50vh;
    min-height: 600px;
    border-radius: 5px;
    display: flex;
  }
`;

const Column = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 50%;
    padding: 30px 45px;
  }
`;

export const ColumnLeft = styled(Column)`
  background: url("${colLeft}") no-repeat top center;
  background-size: cover;
  align-items: flex-start;
  color: #ffffff;
`;

export const ColumnRight = styled(Column)`
  align-items: center;
`;

export const Logo = styled.img`
  margin-bottom: 20px;
`;

export const IcoView = styled.div`
  position: absolute;
  top: 6px;
  right: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url('${icoEye}') no-repeat top center;
  &.slash {
    background-position: bottom center;
  }
`;
