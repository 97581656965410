import {I18n} from 'aws-amplify';

export const verifyError = (code, message) => {
  switch (code) {
    case 'CodeMismatchException':
      return I18n.get('CodeMismatchException.invalid_verification_code');
    case 'NotAuthorizedException':
      if (message === 'Temporary password has expired and must be reset by an administrator.') {
        return I18n.get('NotAuthorizedException.ExpiredTempPassword');
      }
      return I18n.get('NotAuthorizedException.incorrect');
    case 'UsernameExistsException':
      return I18n.get('UsernameExistsException.email_already_exists');
    case 'UserNotConfirmedException':
      return I18n.get('UserNotConfirmedException.user_is_not_confirmed');
    case 'UserNotFoundException':
      return I18n.get('UserNotFoundException.notFound');
    case 'ExpiredCodeException':
      return I18n.get('ExpiredCodeException.invalid_code');
    case 'LimitExceededException':
      return I18n.get('LimitExceededException.limit_exceeded');
    default:
      return 'Inconsistência nos dados enviados, tente novamente mais tarde';
  }
};

export const passwordValidation = (value, setPasswordValidate) => {
  let obj = {};
  if (value.length >= 8) {
    obj = Object.assign(obj, {min: true});
  } else {
    obj = Object.assign(obj, {min: false});
  }

  if (/[a-z]/gm.test(value)) {
    obj = Object.assign(obj, {letterlowercase: true});
  } else {
    obj = Object.assign(obj, {letterlowercase: false});
  }

  if (/[A-Z]/gm.test(value)) {
    obj = Object.assign(obj, {letteruppercase: true});
  } else {
    obj = Object.assign(obj, {letteruppercase: false});
  }

  if (/[0-9]/gm.test(value)) {
    obj = Object.assign(obj, {number: true});
  } else {
    obj = Object.assign(obj, {number: false});
  }
  if (/[!@#$%*()_/\\\-+^&{}:;?.]/gm.test(value)) {
    obj = Object.assign(obj, {letterespecial: true});
  } else {
    obj = Object.assign(obj, {letterespecial: false});
  }
  setPasswordValidate(obj);
};
