import {useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {clearCookie, createCookie} from '../../utils/cookies';

const TokenRefresh = () => {
  useEffect(() => {
    (async () => {
      try {
        const response = await Auth.currentSession();
        createCookie(response);
      } catch (e) {
        clearCookie();
      }
    })();
  });
  return null;
};
export default TokenRefresh;
