// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bfd1fbba-aa68-4610-a52c-57dcbba688e4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fWDlQIhMk",
    "aws_user_pools_web_client_id": "769csba007r0tsl893fnf9pdsj",
    "oauth": {
        "domain": "",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "",
        "redirectSignOut": "",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};

export default awsmobile;
